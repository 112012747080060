import { Field, Form, Formik } from "formik";
import styles from "./styles.module.scss";
import DranNDropUpload from "../../Common/DragNDropUpload";
import {
  ICreateListValues,
  IList,
  IListRow,
} from "../../../types/UploadCenter";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { uploadCenterService } from "../../../services/uploadCenter";
import { QUERY_KEYS } from "../../../common/queryKeys";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import PreviewListTable from "../components/PreviewListTable";
import ListRowFieldAssignments from "../components/ListRowFieldAssignments";
import uploadListValidationSchema from "./validation";
import { listOfRequiredFieldsSavingListRow } from "../../../common/constants";
import Button from "../../Common/Button";
import { ReactComponent as CrossSVG } from "../../../assets/icons/cross.svg";
import { ReactComponent as ArrowUpSVG } from "../../../assets/icons/arrowUp.svg";
import { ReactComponent as DoneSVG } from "../../../assets/icons/done.svg";
import { IAdminSettings } from "../../../types/AdminSettings";
import SelectField from "../../Common/SelectField";
import Loader from "../../Common/Loader";

interface IProps {
  data?: {
    list: Exclude<IList, "rows">;
    rows?: IListRow[];
    page: number;
    total: number;
    totalPages: number;
  };
  adminSettings: IAdminSettings;
}

const Content = ({ data, adminSettings }: IProps) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [stage, setStage] = useState(0);
  // const [isLoading, setIsLoading] = useState(false);

  const { mutateAsync: createList, isPending: isPendingCreate } = useMutation({
    mutationFn: uploadCenterService.createList,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.getAllUploadedLists],
      });
    },
  });

  const { mutateAsync: uploadFile, isPending: isPendingUpdate } = useMutation({
    mutationFn: (body) =>
      uploadCenterService.loadFileToTheList(+data?.list.id!, body),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.getOneUploadedList, data?.list.id!],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.getAllUploadedLists],
      });
    },
  });

  const saveFunction = !!data ? uploadFile : createList;
  const isLoading = isPendingCreate || isPendingUpdate;

  const submitAddingList = async (values) => {
    if (!values.dbFieldsAssignment) return;

    const valuesFields = Object.values(values.dbFieldsAssignment);
    const isValid = !listOfRequiredFieldsSavingListRow.find(
      (field) => !valuesFields.includes(field)
    );

    if (!isValid) return;

    const formData = new FormData();
    formData.append("file", values.rawFile);
    formData.append("name", values.name);
    formData.append("deliverer", values.deliverer);
    formData.append(
      "dbFieldsAssignment",
      JSON.stringify(values.dbFieldsAssignment)
    );

    await saveFunction(formData);
    navigate("/uploadcenter");
  };

  const nextSubmitFunction = (submitForm: () => void) => () => {
    if (stage !== 2) return setStage((prev) => (prev += 1));
    submitForm();
  };

  if (isLoading) return <Loader />

  return (
    <div className={styles.wrapper}>
      <div className={styles.listWrapper}>
        <h1 className={styles.mainHeader}>
          {data ? "Load more contact" : "Add new list"}
        </h1>
        <Formik
          initialValues={{
            name: data?.list.name || "",
            deliverer: data?.list.deliverer || adminSettings.delivererList[0],
            file: null,
            rawFile: null,
            dbFieldsAssignment: undefined,
          }}
          onSubmit={submitAddingList}
          validationSchema={uploadListValidationSchema}
          isInitialValid={false}
        >
          {({ values, setFieldValue, submitForm, errors, isValid }) => {
            const isAssigned = !listOfRequiredFieldsSavingListRow.find(
              (elem) =>
                !Object.values(values.dbFieldsAssignment || {}).includes(elem)
            );

            const isDisabled = (!isAssigned && stage === 2) || !isValid;

            return (
              <Form>
                <div className={styles.fields}>
                  {stage === 0 && (
                    <>
                      <span className={styles.inputLabel}>List name</span>
                      <Field
                        id="name"
                        name="name"
                        type="text"
                        className={`${styles.textInput} ${
                          !!data ? styles.disabledInput : ""
                        }`}
                        placeholder="Input text"
                        disabled={!!data}
                      />
                      <p>{errors.name}</p>

                      <span className={styles.inputLabel}>Deliverer</span>
                      <SelectField id="deliverer" name="deliverer">
                        {adminSettings.delivererList.map((i, idx) => (
                          <option value={i} key={`value-${i}-${idx}`}>
                            {i}
                          </option>
                        ))}
                      </SelectField>
                      <p>{errors.deliverer}</p>
                      <DranNDropUpload
                        name="file"
                        onChangeFunction={(value) =>
                          setFieldValue("file", value)
                        }
                        saveRawFile={(file) => setFieldValue("rawFile", file)}
                        value={values.file}
                      />
                    </>
                  )}

                  {stage === 1 && (
                    <div className={styles.previewTable}>
                      <h3 className={styles.subTitle}>Preview File</h3>
                      <br />

                      <PreviewListTable rows={values.file} />
                    </div>
                  )}

                  {stage === 2 && (
                    <>
                      <div className={styles.previewTable}>
                        <ListRowFieldAssignments
                          rows={values.file!}
                          setFieldsAssignment={(value: {
                            [key: string]: string;
                          }) => setFieldValue("dbFieldsAssignment", value)}
                        />
                      </div>
                    </>
                  )}
                </div>

                <br />

                <div className={styles.buttonPositioner}>
                  <div className={styles.buttonContainer}>
                    <Link to="/uploadcenter">
                      <Button type="button" isOutline>
                        <CrossSVG /> Cancel
                      </Button>
                    </Link>

                    <Button
                      type="button"
                      disabled={isDisabled}
                      onClick={nextSubmitFunction(submitForm)}
                    >
                      {stage === 2 ? (
                        <>
                          <DoneSVG /> Confirm
                        </>
                      ) : (
                        <>
                          <ArrowUpSVG /> Next
                        </>
                      )}
                    </Button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default Content;
