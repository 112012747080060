import { useQuery, useQueryClient } from "@tanstack/react-query";
import { uploadCenterService } from "../../../services/uploadCenter";
import { QUERY_KEYS } from "../../../common/queryKeys";
import styles from "./styles.module.scss";
import ListOfLists from "./components/ListOfLists";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSortUploadLists } from "../../../customHooks/useSortUploadLists";
import Pagination from "../../FunctionalComponents/Pagination";
import SearchBar from "../../FunctionalComponents/SearchBar";
import Loader from "../../Common/Loader";

const UploadCenter = () => {
  const queryClient = useQueryClient();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const togglePage = ({ selected }: { selected: number }) => setPage(selected);
  const selectLimit = (number: number) => setLimit(number);

  const { search, setSearch, sortQuery, order } = useSortUploadLists();

  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.getAllUploadedLists, limit, page],
    queryFn: () =>
      uploadCenterService.getAllLists(
        search,
        sortQuery,
        order,
        limit,
        page + 1
      ),
  });

  useEffect(() => {
    togglePage({ selected: 0 });
    const timeoutId = setTimeout(() => {
      queryClient.cancelQueries({
        queryKey: [QUERY_KEYS.getAllUploadedLists],
      });

      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.getAllUploadedLists],
      });
    }, 200);

    return () => clearTimeout(timeoutId);
  }, [search, sortQuery, order]);

  if (isLoading) return <Loader />;
  if (!data) return <div>No data</div>;

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.mainHeader}>Select list</h2>
      <div className={styles.searchAddBlock}>
        <SearchBar search={search} setSearch={setSearch} />
        <Link to="/uploadlist">
          <button className={styles.addButton}>
            <span>+</span>Add new list
          </button>
        </Link>
      </div>
      <div className={styles.listWrapper}>
        <br />
        {data?.data.length ? (
          <div className={styles.listsAndPagination}>
            <ListOfLists lists={data.data} />
            <Pagination
              totalPages={data.totalPages}
              page={page}
              togglePage={togglePage}
              selectLimit={selectLimit}
            />
          </div>
        ) : (
          <div>No lists. Create a new one...</div>
        )}
      </div>
    </div>
  );
};

export default UploadCenter;
