import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { QUERY_KEYS } from "../../../common/queryKeys";
import { uploadCenterService } from "../../../services/uploadCenter";
import styles from "./styles.module.scss";
import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Button from "../../Common/Button";
import Papa from "papaparse";
import { formateDateTimeLocale } from "../../../utils/formateDate";
import { ReactComponent as DownloadCsvSVG } from "../../../assets/icons/downloadCSV.svg";
import { ReactComponent as LoadMoreSVG } from "../../../assets/icons/loadMore.svg";
import { ReactComponent as ArrowBackSVG } from "../../../assets/icons/arrowBack.svg";
import Content from "./content";
import SearchBar from "../../FunctionalComponents/SearchBar";
import Loader from "../../Common/Loader";

const UploadedList = () => {
  const queryClient = useQueryClient();
  const { id } = useParams();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");

  const togglePage = ({ selected }: { selected: number }) => setPage(selected);
  const selectLimit = (number: number) => setLimit(number);

  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.getOneUploadedList, id, page, limit],
    queryFn: () =>
      uploadCenterService.getOneList(+id!, search, limit, page + 1),
    placeholderData: (prev) => prev,
  });

  const downloadCSV = () => {
    const csv = Papa.unparse(data?.rows!, { delimiter: ";" });

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute(
      "download",
      `${data?.list.name}_${formateDateTimeLocale(new Date())}.csv`
    );
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    togglePage({selected: 0});
    const timeoutId = setTimeout(() => {
      queryClient.cancelQueries({
        queryKey: [QUERY_KEYS.getOneUploadedList],
      });

      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.getOneUploadedList],
      });
    }, 200);

    return () => clearTimeout(timeoutId);
  }, [search]);

  if (isLoading && !data) {
    return <Loader />;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.listWrapper}>
        <div className={styles.listHeader}>
          <div className={styles.buttonAndHeader}>
            <Link to="/uploadcenter">
              <IconButton>
                <ArrowBackSVG />
              </IconButton>
            </Link>
            <h3>{data?.list.name}</h3>
          </div>

          <div className={styles.buttonAndHeader}>
            <div>
              <SearchBar search={search} setSearch={setSearch} />
            </div>
            <Button onClick={downloadCSV} isOutline>
              <p className={styles.noBreakLine}>
                <DownloadCsvSVG /> Download CSV
              </p>
            </Button>

            <Link to={`/uploadlist/${id}`} className={styles.noDecoration}>
              <Button>
                <p className={styles.noBreakLine}>
                  <LoadMoreSVG /> Load More
                </p>
              </Button>
            </Link>
          </div>
        </div>

        {data?.rows && data.rows.length > 0 ? (
          <Content
            data={data}
            page={page}
            togglePage={togglePage}
            selectLimit={selectLimit}
          />
        ) : (
          <div>No data saved in the list</div>
        )}
      </div>
    </div>
  );
};

export default UploadedList;
