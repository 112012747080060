import { Bars } from "react-loader-spinner";
import styles from "./styles.module.scss";

interface IProps {
  isAbsolute?: boolean;
  size?: number;
}

const Loader = ({ isAbsolute = true, size = 80 }: IProps) => {
  return (
    <Bars
      height={`${size}`}
      width={`${size}`}
      color="#e4348b"
      ariaLabel="bars-loading"
      wrapperClass={isAbsolute ? styles.absolute : ""}
    />
  );
};

export default Loader;
